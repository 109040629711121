import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../Icons';
import OvertimeSupervisorDatatable from './OvertimeSupervisorDatatable';
import { profileTeamDashboardRequest } from '../../../../requests/dashboards';
import { camelCaseEmptyStringRecursive } from '../../../../services/utils';

const TeamRequests = () => {
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const { currentEmployee } = useSelector(state => state.auth);
  const [key, setKey] = useState('overtime');
  const [cards, setCards] = useState({});

  const fetchCardData = () => {
    profileTeamDashboardRequest({
      dispatch,
      successCallback: response => {
        setCards(camelCaseEmptyStringRecursive(response.data).allRequestsCount);
      }
    });
  };
  useEffect(fetchCardData, [moreData]);

  return (
    <>
      <Card className="mb-1">
        <Row>
          <Col sm={12} className='ml-4'>
            <Tab.Container id="supervisor-management.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link className="mb-2 mt-2" eventKey="overtime">
                    {cards?.cardBoss?.overtimeRequestCount > 0 && (
                      <Badge
                        variant="danger"
                        className="badge-circle"
                        style={{ marginTop: '-10px', marginLeft: '117px' }}
                      >
                        {cards.cardBoss.overtimeRequestCount}
                      </Badge>
                    )}
                    <Icon icon="clock" />
                    Horas Extras
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="overtime">
                  <OvertimeSupervisorDatatable
                    currentEmployee={currentEmployee}
                    preName="overtime"
                    defaultStatus="0"
                    moreData={moreData}
                    setMoreData={setMoreData}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default TeamRequests;
