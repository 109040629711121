import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { sendAlert } from '../../../../actions/utils';
import ComponentDataTable from '../../../Utils/DataTable';
import { DefaultModal, SimpleCenteredModal } from '../../../Utils/Modal';
import { AbilityContext } from '../../../../config/abilityContext';
import { debounceIndexOvertimeRequest, deleteOvertimeRequest } from '../../../../requests/overtimes';
import OvertimeShow from '../../../../screens/Profile/Overtime/OvertimeShow';
import OvertimeColumns from './OvertimeColumns';
import { camelCaseEmptyStringRecursive } from '../../../../services/utils';
import OvertimeInfoTable from './OvertimeInfoTable';
import { FormikSelect } from '../../../Utils/Select';

const OvertimeSupervisorDatatable = ({
  preName,
  defaultStartDate = null,
  defaultEndDate = null,
  defaultStatus,
  withMassActions,
  currentEmployee,
  moreData,
  setMoreData
}) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalSize, setModalSize] = useState('md');
  const [modalButtons, setModalButtons] = useState(true);
  const [onRequest, setOnRequest] = useState(true);
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(defaultStatus);
  const overtimeStatus = [
    { label: 'Todos', value: '' },
    { label: 'Pendiente', value: '0' },
    { label: 'Aprobado', value: '1' },
    { label: 'Rechazado', value: '2' }
  ];

  const handleRequest = params => {
    if (status === '0') {
      params.pending_review = currentEmployee;
    }

    setOnRequest(true);
    const sendParams = {
      ...params,
      filter_by_supervisor: currentEmployee,
      review: currentEmployee,
      status
    };
    const request = async () => {
      debounceIndexOvertimeRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data, metadata } = response.data;
          setWorkflowRequests(data);
          setAmount(metadata.amount);
          setOnRequest(false);
        }
      });
    };
    request();
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
    setOnRequest(false);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const removeOvertimeRequest = item => {
    setOnRequest(true);
    deleteOvertimeRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => handleModalClose()
    });
  };

  const handleManageRequest = item => {
    setModalBody(
      <OvertimeInfoTable
        item={item}
        moreData={moreData}
        setModalShow={setModalShow}
        setMoreData={setMoreData}
        showErrorMessage
      />
    );
    setModalButtons(false);
    setModalShow(true);
    setModalSize('xl');
  };

  const handleActions = (item, action) => {
    setModalItem(item);
    switch (action) {
      case 'show':
        setModalTitle('Solicitud de Horas Extras');
        setModalBody(<OvertimeShow item={item} />);
        setCenterModalShow(true);
        break;

      case 'destroy':
        setModalTitle('Eliminar Solicitud de Horas Extras');
        setModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
        setModalShow(true);
        setModalAction(() => removeOvertimeRequest);
        break;

      case 'actions':
        handleManageRequest(camelCaseEmptyStringRecursive(item));
        break;

      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee' || 'employee.full_name':
        return { sort_employees: name };
      case 'status':
        return { sort_status: name };
      case 'request_date':
        return { sort_column: 'created_at' };
      default:
        return { sort_column: name };
    }
  };

  useEffect(() => {
    setStatus(defaultStatus);
  }, [preName, defaultStatus]);

  return (
    <>
      <ComponentDataTable
        columns={OvertimeColumns(handleActions, ability)}
        customHeaderCenter={
          <>
            <FormikSelect
              options={overtimeStatus}
              label=""
              value={status}
              defaultValue={status}
              onChange={data => {
                setStatus(data ? data.value : '');
                setOnRequest(true);
                setMoreData(!moreData);
              }}
              placeholder="Todos"
            />
          </>
        }
        defaultEndDate={defaultEndDate}
        defaultStartDate={defaultStartDate}
        data={workflowRequests}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        onRequest={onRequest}
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
        preName={preName || 'overtime'}
        rangePicker
        resourceRequest={handleRequest}
        selectableRows={withMassActions}
        totalRows={amount}
        withStartDate
        withEndDate
        withMassActions={withMassActions}
      />
      <SimpleCenteredModal
        title={modalTitle}
        size="md"
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        modalSize={modalSize}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={modalButtons}
        withConfirm={modalButtons}
      />
    </>
  );
};

export default OvertimeSupervisorDatatable;
