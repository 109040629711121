import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Accordion, Card } from 'react-bootstrap';
import { FormikCheckBox, FormikInput, FormikSelect, InputSelect, LinkBtn, FormikNumber } from '../../../components';
import { debounceIndexBookkeepersRequest } from '../../../requests/bookkeepers';
import { debounceIndexConceptLreRequest } from '../../../requests/balances';
import { conceptTypeEnum, ineCodeEnum, emptyConcept, conceptFE } from './FormOptions';

import validationSchema from './formValidationSchema';

const ConceptForm = props => {
  const dispatch = useDispatch();
  const {
    balance,
    errors,
    onHide,
    setFieldValue,
    setFieldTouched,
    submitVariant,
    touched,
    values,
    isSubmitting,
    action
  } = props;

  const { preloaded } = balance;
  const [balanceType, setBalanceType] = useState('');
  const [accordion, setAccordion] = useState('characteristics');

  const resultFetchDataBookkeeper = response => {
    const result = response.data.data;
    return result.map(element => ({
      label: element.name,
      value: element.id
    }));
  };

  const resultfetchConceptLre = response => {
    const result = response.data.data;
    return result.map(element => ({
      label: `${element.code} - ${element.name}`,
      value: element.id
    }));
  };

  const fetchDataBookkeeper = (inputValue, callback) => {
    debounceIndexBookkeepersRequest({
      dispatch,
      params: {
        query: inputValue,
        paginate: false
      },
      successCallback: data => callback(resultFetchDataBookkeeper(data))
    });
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  useEffect(() => {
    setBalanceType(balance.balanceType);
  }, [balance]);

  const onchangeconceptType = (field, data) => {
    setFieldValue(field.name, data ? data.value : '');
    setBalanceType(data.value);

    if (action === 'new') {
      Object.keys(emptyConcept).forEach(key => {
        if (!['code', 'balanceType'].includes(key)) {
          setFieldValue(`balance[${key}]`, emptyConcept[key]);
        }
      });
    }
  };
  const fetchConceptLre = (inputValue, callback) => {
    debounceIndexConceptLreRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        filter_type: balanceType
      },
      successCallback: response => {
        callback(resultfetchConceptLre(response));
      }
    });
  };

  return (
    <Form>
      <Accordion defaultActiveKey="0">
        <Card>
          <Col className={`${accordion === 'characteristics' ? 'top-header-green' : 'top-header-light-gray'}`}>
            <Accordion.Toggle
              as={Col}
              eventKey="0"
              className="card-header-title"
              onClick={() => setAccordion('characteristics')}
            >
              CARACTERISTICAS
            </Accordion.Toggle>
          </Col>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="div-content">
              <Row>
                <Col md={6}>
                  <Field name="balance[balanceType]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Tipo de Concepto"
                        placeholder="Seleccionar Tipo de Concepto"
                        options={conceptTypeEnum}
                        defaultValue={balance.balanceType}
                        onChange={data => onchangeconceptType(field, data)}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        isDisabled={preloaded && action === 'edit'}
                      />
                    )}
                  </Field>
                </Col>

                <Col md={6}>
                  <Field name="balance[code]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Código"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        defaultValue={balance.code}
                        disabled={preloaded && action === 'edit'}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Field name="balance[name]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Descripción"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        defaultValue={balance.name}
                        disabled={preloaded && action === 'edit'}
                      />
                    )}
                  </Field>
                </Col>
                {['asset', 'discount'].includes(balanceType) && (
                  <Col md={6}>
                    <Field name="balance[bookkeeperIds]">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          abbr
                          isMulti
                          isClearable
                          label="Cuenta Contables"
                          placeholder="Seleccionar Cuenta Contables"
                          values={values.balance}
                          model={[balance, 'bookkeeper']}
                          request={fetchDataBookkeeper}
                          onChange={data => {
                            handleNewMulti(data || [], field.name, true);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                )}
              </Row>
              <Row>
                {balanceType === 'discount' && (
                  <Col md={6}>
                    <Field name="balance[conceptLreId]">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          label="Concepto LRE"
                          placeholder="Seleccionar Concepto LRE"
                          values={values.balance}
                          model={[balance, 'conceptLre']}
                          request={fetchConceptLre}
                          onChange={data => setFieldValue(field.name, data.value)}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                )}

                {balanceType === 'asset' && (
                  <Col md={6}>
                    <Field name="balance[ineCode]">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          abbr
                          label="Agrupación INE"
                          placeholder="Seleccionar Agrupación INE"
                          tooltipText="Código de agrupación de cargos para las empresas que deben responder la
                               Encuesta INE Mensual de Remuneraciones y Costo de la Mano de Obra.
                               Si completa este campo podrá generar automáticamente dicho informe."
                          options={ineCodeEnum}
                          defaultValue={balance.ineCode}
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isDisabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                )}
                {balanceType === 'asset' && (
                  <Col md={6}>
                    <Field name="balance[conceptLreId]">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          isClearable
                          label="Concepto LRE"
                          placeholder="Seleccionar Concepto LRE"
                          values={values.balance}
                          model={[balance, 'conceptLre']}
                          request={fetchConceptLre}
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                )}
              </Row>
              {balanceType === 'asset' && (
                <Row>
                  <Col md={6}>
                    <Field name="balance[taxable]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Imponible"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>

                  <Col md={6}>
                    <Field name="balance[tributable]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Tributable"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              )}
              {['asset'].includes(balanceType) && (
                <Row>
                  <Col md={6}>
                    <Field name="balance[proportional]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Proporcional"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={6}>
                    <Field name="balance[overtimeBase]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Base de Sobretiempo"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              )}
              {['discount'].includes(balanceType) && (
                <Row>
                  <Col md={6}>
                    <Field name="balance[settlementAdvance]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Anticipo Liquidación"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={6}>
                    <Field name="balance[legalDiscount]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Descuento Legal"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              )}
              <Row>
                {balanceType === 'asset' && (
                  <Col md={6}>
                    <Field name="balance[runningWeek]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Semana Corrida"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                )}
                {['asset', 'discount'].includes(balanceType) && (
                  <Col md={6}>
                    <Field name="balance[printOnLiquidation]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Se imprime en liquidación"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                )}
              </Row>
              {balanceType === 'asset' && (
                <Row>
                  <Col md={6}>
                    <Field name="balance[gratificationBase]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Base de Gratificación"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={6}>
                    <Field name="balance[reliquidation]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          label="Reliquidación"
                          custom
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              )}

              {['asset', 'discount'].includes(balanceType) && (
                <Row>
                  <Col md={6}>
                    <Field name="balance[calculationOrder]">
                      {({ field }) => (
                        <FormikNumber
                          {...field}
                          abbr
                          label="Orden en la liquidación"
                          tooltipText="Define el orden en que se mostrará en la liquidación."
                          fieldName="balance[calculationOrder]"
                          errors={errors}
                          touched={touched}
                          defaultValue={balance.calculationOrder}
                          thousandSeparator=""
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>

                    {/* <Field name="loan[parsedAmount]">
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    leftAddon="$"
                    fieldName="loan[amount]"
                    value={amount}
                    setFieldValue={setFieldValue}
                    label="Monto Préstamo"
                    errors={errors}
                    touched={touched}
                    disabled={isResolved}
                  />
                )}
              </Field> */}
                  </Col>
                </Row>
              )}
              {action === 'edit' && (
                <Row>
                  <Col md={6}>
                    <Field name="balance[active]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          field={field}
                          custom
                          label="Activo"
                          type="switch"
                          disabled={preloaded && action === 'edit'}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {balanceType === 'asset' && (
          <Card>
            <Col className={`${accordion === 'attributes' ? 'top-header-green' : 'top-header-light-gray'}`}>
              <Accordion.Toggle
                as={Col}
                eventKey="1"
                className="card-header-title"
                onClick={() => setAccordion('attributes')}
              >
                ATRIBUTOS
              </Accordion.Toggle>
            </Col>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="div-content">
                <Row>
                  <Col md={6}>
                    <h4 className="text-center">Otros Atributos</h4>
                    <Row>
                      <Col md={12}>
                        <Field name="balance[electronicSettlement]">
                          {({ field }) => (
                            <FormikSelect
                              {...field}
                              label="Concepto Finiquito Electrónico"
                              placeholder="Seleccionar concepto FE"
                              tooltipText="Para generar correctamente el archivo masivo de finiquitos electrónico, asocie el concepto con alguna alternativa de este selector."
                              options={conceptFE}
                              defaultValue={balance.electronicSettlement}
                              onChange={data => setFieldValue(field.name, data ? data.value : '')}
                              setFieldTouched={() => setFieldTouched(field.name)}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={12}>
                        <Field name="balance[royalties]">
                          {({ field }) => (
                            <FormikCheckBox {...field} field={field} label="Regalías en especie" custom type="switch" />
                          )}
                        </Field>
                      </Col>
                      <Col md={12}>
                        <Field name="balance[assetVariable]">
                          {({ field }) => (
                            <FormikCheckBox {...field} field={field} label="Haber Variable" custom type="switch" />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <h4 className="text-center">Base cálculo finiquito</h4>
                    <Row>
                      <Col md={6}>
                        <Field name="balance[compensationYearsService]">
                          {({ field }) => (
                            <FormikCheckBox
                              {...field}
                              field={field}
                              label="Indemnización Años de Servicio"
                              custom
                              type="switch"
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={6}>
                        <Field name="balance[holidayCompensation]">
                          {({ field }) => (
                            <FormikCheckBox
                              {...field}
                              field={field}
                              label="Indemnización Feriado"
                              custom
                              type="switch"
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Field name="balance[priorNoticeCompensation]">
                          {({ field }) => (
                            <FormikCheckBox
                              {...field}
                              field={field}
                              label="Indemnización Aviso Previo"
                              custom
                              type="switch"
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={6}>
                        <Field name="balance[voluntaryCompensation]">
                          {({ field }) => (
                            <FormikCheckBox
                              {...field}
                              field={field}
                              label="Indemnización Voluntaria"
                              custom
                              type="switch"
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Field name="balance[observation]">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          as="textarea"
                          rows={3}
                          label="Observación"
                          placeholder="Cualquier información adicional que desee agregar al concepto."
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          defaultValue={balance.observation}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
      <Row className="d-flex justify-content-end mt-1 mb-3">
        <Col md={2}>
          <LinkBtn variant="dark" className="btn-secondary btn-block" block to="/remunerations_settings">
            Cancelar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <Button type="submit" className="btn-block" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { balance } = props;
  return { balance };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: params => params.action !== 'new'
})(ConceptForm);
